// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Names from './components/Names';
import Profile from './components/Profile';
import ListInvoices from './components/ListInvoices'; // Import ListInvoices component
import ListQuotations from './components/ListQuotations';
import axios from 'axios';
import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const App = () => {
  const [selectedName, setSelectedName] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  useEffect(() => {
    // Provjeri postoji li token u kolačiću
    const token = Cookies.get('token');
    if (token) {
      setIsLoggedIn(true);  // Ako postoji token, postavi korisnika kao prijavljenog
    }
  }, []);

  const handleNameSelect = (name) => {
    setSelectedName(name);
  };

  const handleLogout = async () => {
    try {
      // Pošaljite POST zahtjev za logout
      const response = await axios.post(`${API_URL}/logout`, {}, { withCredentials: true });
  
      // Provjera statusa odgovora
      if (response.status === 200) {
        // Ako je logout uspješan
        setSelectedName('');
        setIsLoggedIn(false);
      } else {
        // Ako nije 200, obradi grešku
        alert('Logout failed');
      }
    } catch (error) {
      // Ako dođe do greške tijekom zahtjeva
      console.error('Error logging out:', error);
      alert('There was an error logging out');
    }
  };
  
  

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/names" element={<Names onNameSelect={handleNameSelect} />} />
        <Route path="/profile" element={<Profile selectedName={selectedName} onLogout={handleLogout} />} />
        <Route 
          path="/invoices" 
          element={<ListInvoices 
            selectedName={selectedName} 
            onLogout={handleLogout} 
          /> }
          />
          <Route 
          path="/quotations" 
          element={<ListQuotations 
            selectedName={selectedName} 
            onLogout={handleLogout} 
          />} 
        /> 
          
      </Routes>
    </Router>
  );
};

export default App;
