import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { jsPDF } from 'jspdf';
import './InvoicePreviewModal.css';
import axiosInstance from './utils/axiosInstance'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';


// Komponenta za unos e-mail adrese
const EmailModal = ({ isOpen, onRequestClose, email, onSend }) => {
  const [emailInput, setEmailInput] = useState(email || '');

  // Resetiraj emailInput kada se modal otvori
  useEffect(() => {
    if (isOpen) {
      setEmailInput(email || ''); // Ažuriraj email na osnovu prop-a
    }
  }, [isOpen, email]);

  const handleSend = () => {
    onSend(emailInput);
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal-content2">
      <h2 style={{ color: 'white' }}>Pošalji račun na e-mail</h2>
      <input
        type="email"
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
        placeholder="Upiši e-mail"
        style={{ width: '100%', marginBottom: '10px' }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button onClick={handleSend}>Pošalji</button>
        <button onClick={onRequestClose}>Zatvori</button>
      </div>
    </Modal>
  );
};


const InvoicePreviewModal = ({ isOpen, onRequestClose, invoiceImageUrl, invoiceData }) => {
  const [scale, setScale] = useState(1);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [email, setEmail] = useState(invoiceData?.customer?.email || '');
  useEffect(() => {
    const handlePreview = () => {
      if(invoiceImageUrl)
        setScale(1.8); // Na primjer, 50% za preview
    };
    if (isOpen) {
      handlePreview(); // Pozovi handlePreview kada se modal otvori
      if(invoiceData)
      {
        setEmail(invoiceData.customer?.email || ''); // Ažuriraj email ako je invoiceData dostupna
      }

    }
  }, [isOpen, invoiceData, invoiceImageUrl]);

  if (!invoiceData) {
    return null; // ili možeš dodati loader ili poruku o grešci
  }


  const saveAsPDF = () => {
    const pdf = new jsPDF('p', 'pt', 'a4'); // Postavi format PDF-a na A4
    const img = new Image();
    img.src = invoiceImageUrl;

    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
      const quality = 0.75;
      pdf.addImage(invoiceImageUrl, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST', quality);
      pdf.save(`${invoiceData.invoiceNumber ? `Racun-${invoiceData.invoiceNumber}-P1-1` : `Ponuda-${invoiceData.quotationNumber}`}.pdf`);
    };
  };



  const handleSendEmail = () => {
    setIsEmailModalOpen(true); // Otvori modal za unos e-mail adrese
  };

  const handleEmailSend = async (email) => {
    const pdf = new jsPDF('p', 'pt', 'a4'); // Kreiraj PDF za slanje
    const img = new Image();
    img.src = invoiceImageUrl;
  
    img.onload = async () => {
      const imgWidth = img.width;
      const imgHeight = img.height;
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
  
      const quality = 0.5; // Smanjite kvalitetu na 50%
      pdf.addImage(invoiceImageUrl, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST', quality);      

      const pdfBlob = pdf.output('blob');
      const reader = new FileReader();
      const isInvoice = invoiceData.invoiceNumber?true:false;
      
      reader.onloadend = async () => {
        const base64data = reader.result; // Ovdje je base64 string

        try {
          await axiosInstance.post(`${API_URL}/send-invoice`, {
            email,
            pdf: base64data, // Pošalji base64 PDF
            invoiceNumber: isInvoice ? invoiceData.invoiceNumber : invoiceData.quotationNumber,
            operator: invoiceData.operator,
            isInvoice: isInvoice
          },
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          console.log(`E-mail poslan na: ${email}`);
          setIsEmailModalOpen(false); // Zatvori modal nakon slanja
        } catch (error) {
          console.error('Greška pri slanju e-maila:', error);
          alert('Došlo je do greške pri slanju e-maila.');
        }
      };
      
      reader.readAsDataURL(pdfBlob); // Konvertuj blob u base64
    };
  
    img.onerror = (error) => {
      console.error('Greška pri učitavanju slike:', error);
    };
  };
  

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal-content2">
        <div>
          <h2 style={{ color: 'white' }}>
            {invoiceData.invoiceNumber ? 'Pregled računa' : 'Pregled ponude'}
          </h2>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <button onClick={saveAsPDF} style={{ marginRight: '10px' }}>Spremi kao PDF</button>
            <button onClick={handleSendEmail} style={{ marginRight: '10px' }}>Pošalji na mail</button>
            <button onClick={onRequestClose}>Zatvori</button>
          </div>
          
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'flex-start', 
            maxHeight: 'calc(80vh - 80px)', 
            overflow: 'auto', // Omogući skrolanje po oba smjera
            marginTop: '0px' 
          }}>
          <img 
            src={invoiceImageUrl} 
            style={{ 
              transform: `scale(${scale})`, // Koristimo scale za povećanje
              transformOrigin: 'top left', // Poravnava sliku na gornji lijevi kut
              objectFit: 'contain'
            }} 
            alt="Invoice Preview" 
          />
        </div>

        </div>
      </Modal>
 
  
      {/* Modal za unos e-mail adrese */}
      <EmailModal
        isOpen={isEmailModalOpen}
        onRequestClose={() => setIsEmailModalOpen(false)}
        email={email}
        onSend={handleEmailSend}
      />
    </>
  );
  
};

export default InvoicePreviewModal;
