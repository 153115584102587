import React from 'react';
import { useNavigate } from 'react-router-dom'; // Uvezi useNavigate
import './Names.css';

const Names = ({ onNameSelect }) => {
  const names = ['Dubravka', 'Josipa', 'Jurica', 'Lucija', 'Katarina', 'Marija', 'Mirna'];
  const navigate = useNavigate(); // Inicijaliziraj navigaciju

  const handleClick = (name) => {
    onNameSelect(name); // Postavi odabrano ime
    navigate('/profile'); // Prebaci na profilnu stranicu
  };

  return (
    <div className="names-container">
      {names.map((name, index) => (
        <div
          key={index} 
          className="name-circle" 
          onClick={() => handleClick(name)} // Postavi handleClick na klik
        >
          {name}
        </div>
      ))}
    </div>
  );
};

export default Names;
