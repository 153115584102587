// src/components/Header.js
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./Header.css";

const Header = ({ selectedName, handleChangeUser, handleLogout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedName) {
      navigate('/names'); // Preusmjeri na "/names" ako je selectedName prazno
    }
  }, [selectedName, navigate]); // Provjerava promjenu selectedName

  return (
    <header className="profile-header">
      <div className="header-left">
        Sustav koristi <strong>{selectedName}</strong>. Nisi {selectedName}?
        <button onClick={handleChangeUser} className="change-user-button">
          Promijeni korisnika
        </button>
      </div>
      <div className="header-right">
        <Link to="/profile">Izdavanje računa</Link>
        <Link to="/invoices">Popis računa</Link>
        <Link to="/quotations">Popis ponuda</Link> {/* Dodano za popis ponuda */}
        <button onClick={handleLogout} className="logout-button">Odjava</button>
      </div>
    </header>
  );
};

export default Header;
