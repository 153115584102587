
// src/axiosInstance.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/'; // Koristi env varijablu za API URL

const axiosInstance = axios.create({
  baseURL: API_URL, // Postavi osnovnu URL adresu za sve zahtjeve
  headers: {
    'Content-Type': 'application/json',
  },
});

// Dodaj interceptor za automatsko dodavanje tokena u zaglavlje svakog zahtjeva
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Ili koristite neki drugi način pohrane tokena
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Dodaj interceptor za rukovanje greškama (npr. ako token istekne)
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {

      localStorage.removeItem('token');
      window.location.href = '/'; // Preusmjeri na login
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
